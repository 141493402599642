<template>
     <div class="page-container">
		<div style="line-height: 40px;">
			<el-checkbox v-model="checked_struct">还原表结构信息
				<span v-if="struct_file">（{{struct_file}})</span>
				<span v-else>（未找到表结构书）</span>
			</el-checkbox>
		</div>
		 <div  style="height:60vh;overflow: auto;">
			 <el-table
				 :data="tableData.filter(data => !search || data.filename.toLowerCase().includes(search.toLowerCase()))" 
				 border
				 style="width: 100%"
				ref="multipleTable"
				@selection-change="handleSelectionChange">
				   <el-table-column
					  type="selection">
				   </el-table-column>
					<el-table-column
					   fixed
						prop="filename"
					   label="文件名">
					</el-table-column>
					<el-table-column
					   fixed
						prop="bak_time"
					   label="备份时间">
					</el-table-column>
					<el-table-column
					   fixed
						prop="filesize"
					   label="文件大小"> 
					</el-table-column>
				<el-table-column
					align="right">
					<template slot="header" slot-scope="scope">
					  <el-input
						v-model="search"
						size="mini"
						placeholder="输入关键字搜索"/>
					</template>
				  </el-table-column>
			  </el-table>
			</div>
		  <div style="margin-bottom: 20px">
			<p style="color: #606266;font-weight: bold;">总共：{{total}} 个备份文件</p>
			<el-button @click="recovery" type="primary">提交还原</el-button>
			<el-button @click="resetCheckbox">重置勾选</el-button>
		  </div>
    </div>
</template>
<script>
export default {
    inject:['reload'],
   data() {
      return {
        tableData:[],
        total:0,
		search: '',
		multipleSelection:[],
		checked_struct:true,
		struct_file:''
      }
   },
   created(){
    
   },
   mounted(){
   },
   methods:{
       getData(){
            this.$api.post('DataBak/index',{
				type:1
			},res=>{
                if(res.code == 200){
                    this.tableData =  res.data.list??[]
                    this.total =  res.data.count 
					this.struct_file =  res.data.struct_file
                }else{
                    this.$message.error(res.msg)
                }
            })
       },
	   
	   //监听表格多选框
	   handleSelectionChange(val){
	       this.multipleSelection  = []
	       var list = val;
	        list.forEach(item=>{
	            this.multipleSelection.push(item.filename)
	        })
	   },
	   resetCheckbox(){	//重置勾选
			this.$refs['multipleTable'].clearSelection()
			this.multipleSelection  = []
			this.checked_struct = false
	   			
	   },
		recovery(){	//数据还原
			if(this.multipleSelection.length < 1){
				this.$message.error('请选择要还原的数据文件')
				return false
			}
			this.$alert("确定还原选中表数据吗？", "提示", {
				// confirmButtonText:'确定',
				showConfirmButton: true,
				showCancelButton: true,
				callback: action => {
					if (action == "confirm") {
						const loading = this.$loading({
						   lock: true,
						   text: '还原数据中，需要点时间；请勿关闭',
						   spinner: 'el-icon-loading',
						   background: 'rgba(0, 0, 0, 0.7)'
						});
						this.$api.post('DataBak/recovery',{
							checked_status:this.checked_struct==true?1:0,
						    table_struct_file:this.struct_file??'',
							tables:this.multipleSelection
						},res=>{
							if(res.code == 200){
								this.$message.success(res.msg)
							}else{ 
								this.$message.error(res.msg)  
							}
							loading.close()
						})
					}
					else if (action == "cancel") {
						
					}
				}
			});
		},
  
   }

}
</script>
<style scoped>
</style>