<template>
     <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
            可备份当前系统的数据以及恢复指定时间的备份数据
         </div>  
		   <el-tabs style="height: 200px; " @tab-click="handleClick">
		     <el-tab-pane label="数据备份" >
				 <div style="line-height: 40px;">
				 	<el-checkbox v-model="checked_struct">备份表结构信息</el-checkbox>
				 </div>
				 <div  style="height:60vh;overflow: auto;">
					 <el-table
					     :data="tableData.filter(data => !search || data.table_name.toLowerCase().includes(search.toLowerCase()))" 
					     border
					 	ref="multipleTable"
					     style="width: 100%"
					 	@selection-change="handleSelectionChange">
					   <el-table-column
						  type="selection">
					   </el-table-column>
						<el-table-column
						   fixed
							prop="table_name"
						   label="数据表名">
						</el-table-column>
						  <el-table-column
							fixed
							prop="count"
							 label="数据条数">
						  </el-table-column>
						<el-table-column
							align="right">
							<template slot="header" slot-scope="scope">
							  <el-input
								v-model="search"
								size="mini"
								placeholder="输入关键字搜索"/>
							</template>
						  </el-table-column>
					  </el-table>
				 </div>
				 <div style="margin-bottom: 20ppx">
					  <p style="color: #606266;font-weight: bold;">总共：{{total}} 张数据表</p>
					<el-button @click="backup" type="primary">提交备份</el-button>
					<el-button @click="resetCheckbox">重置勾选</el-button>
				</div>
			 </el-tab-pane>
			 
		     <el-tab-pane label="数据还原">
				 <RecoveryVue ref="recoveryData"></RecoveryVue>
			 </el-tab-pane>
		   </el-tabs>
    </div>
</template>
<script>
import RecoveryVue from './Recovery.vue'
export default {
	components:{
		RecoveryVue
	},
    inject:['reload'],
   data() {
      return {
        tableData:[],
        currentPage:1,
        pagesize:10,
        total:0,
        search_key:{
            recovery_id:''
        },
		search: '',
		multipleSelection:[],
		checked_struct:true
      }
   },
   created(){
       this.getData()
   },
   mounted(){
   },
   methods:{
       getData(){
            this.$api.post('DataBak/index',{
				type:0
			},res=>{
                if(res.code == 200){
                    this.tableData =  res.data.list??[]
                    this.total =  res.data.count
                }else{
                    this.$message.error(res.msg)
                }
            })
       },
		handleClick(tab){
			if(tab.index < 1){
				this.getData()
			}else{
				this.$refs.recoveryData.getData()
			}
			
		},
	   //监听表格多选框
	   handleSelectionChange(val){
	       this.multipleSelection  = []
	       var list = val;
		   // console.log(list)
	        list.forEach(item=>{
	            this.multipleSelection.push(item.table_name)
	        })
	   },
	   resetCheckbox(){	//重置勾选
			this.$refs['multipleTable'].clearSelection()
			this.multipleSelection  = []
			
	   },
		backup(){	//数据备份
			if(this.multipleSelection.length < 1){
				this.$message.error('请选择数据表')
				return false
			}
			const loading = this.$loading({
			   lock: true,
			   text: '备份数据中，需要点时间；请勿关闭',
			   spinner: 'el-icon-loading',
			   background: 'rgba(0, 0, 0, 0.7)'
			});
			this.$api.post('DataBak/backup',{
				tables:this.multipleSelection,
				checked_status:this.checked_struct==true?1:0
			},res=>{
				if(res.code == 200){
					this.$message.success(res.msg)
					this.reload();
				}else{
					this.$message.error(res.msg)
				}
				loading.close()
			})
		},
	
   }

}
</script>
<style scoped>
</style>